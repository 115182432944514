import React from 'react';
import { graphql } from 'gatsby';
import {
  Hero,
  // Video,
  Content,
  Services,
  Process,
  Clients,
  Partners,
  Contact,
  Map,
} from 'src/sections';
import * as styles from 'styles/homepage.module.scss';

const Homepage = (props) => {
  const { data: cmsData } = props;

  return (
    <main className={styles.homepage}>
      <Hero cmsData={cmsData} />

      {/* <Video cmsData={cmsData} style={{display: "none"}} /> */}

      <Content cmsData={cmsData} />

      <Services cmsData={cmsData} />

      <Process cmsData={cmsData} />

      <Clients cmsData={cmsData} />

      <Map cmsData={cmsData} />

      <Partners cmsData={cmsData} />

      <Contact cmsData={cmsData} />
    </main>
  );
};

export default Homepage;

export const query = graphql`
  query HomepageQuery($locale: String) {
    datoCmsSite {
      locale
      locales
    }
    seoData: datoCmsSite(locale: { eq: $locale }) {
      globalSeo {
        siteName
        fallbackSeo {
          description
          title
          image {
            fluid(maxWidth: 760, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
    datoCmsHomepage(locale: { eq: $locale }) {
      seo {
        metaTags {
          title
        }
      }
      topHeading
      mottoTexts {
        text
      }
      video {
        url
      }
      whatWeDoTitle
      whatWeDoContent
      services {
        title
        slug
        backgroundImage {
          url
        }
        content
        technologiesTitle
        technologies {
          technologyName
        }
      }
      processTitle
      process {
        title
        content
        slug
        backgroundImage {
          url
        }
      }
      clientsTitle
      clientsContent
      clientsList {
        clientAgency
        clientName
        clientShort
      }
      mapText
      mapMarkers {
        yCoord
        xCoord
        label
      }
      partnersLogos {
        url
      }
      contactContent
    }
    datoCmsHeader(locale: { eq: $locale }) {
      team {
        phone
        email
        photo {
          fluid(maxWidth: 100, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      menuText
      menu {
        link
        text
      }
    }
    datoCmsFooter(locale: { eq: $locale }) {
      locale
      reviewLabel
      reviewMedia {
        url
        alt
        fluid(maxWidth: 180, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      footerLinks {
        title
        link
      }
      reviewLink
    }
  }
`;
