import React from 'react';
import { navigate } from 'gatsby';
import { scroller } from 'react-scroll';

const ServiceContent = ({ content, ...props }) => {
  const handleClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      const href = e.target.getAttribute('href');

      if (href.startsWith('/')) {
        e.preventDefault();
        navigate(href);
      } else if (href.startsWith('#')) {
        e.preventDefault();
        scroller.scrollTo(href.substring(1), {
          duration: 2500,
          delay: 0,
          offset: -50,
        });
      }
    }
  };
  return <div onClick={handleClick} dangerouslySetInnerHTML={{ __html: content }} {...props} />;
};

export default ServiceContent;
