import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Element } from 'react-scroll';
import * as styles from 'styles/partners.module.scss';

SwiperCore.use([Autoplay]);

const Partners = ({ cmsData }) => {
  const { partnersLogos } = cmsData.datoCmsHomepage;

  return (
    <section className={styles.sectionPartners}>
      <Element name="partners" className={`page-container ${styles.pageContainer}`}>
        <div className={styles.offsetWrapper}>
          <h2 className={styles.h2}>Partners</h2>
        </div>
      </Element>
      <div className={styles.partners}>
        <Swiper
          slidesPerView='auto'
          spaceBetween={60}
          loop
          freeMode
          loopedSlides={10}
          speed={7500}
          autoplay={{ delay: 1 }}
        >
          {partnersLogos.map(({ url }) => (
            <SwiperSlide key={url}>
              <img src={url} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;
