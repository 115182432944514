import React from 'react';
import Tippy from '@tippyjs/react';
import { Element } from 'react-scroll';
import MapIcon from 'assets/icons/map.svg';
import * as styles from 'styles/map.module.scss';

const Map = ({ cmsData }) => {
  const { mapText, mapMarkers: markers } = cmsData.datoCmsHomepage;
  return (
    <section className={styles.sectionMap}>
      <Element name="map" className="page-container">
        <div className={styles.mapText}>{mapText}</div>
        <div className={styles.mapContainer}>
          <MapIcon className={styles.map} />
          <div className={styles.markers}>
            {markers &&
              markers.map((marker) => {
                return (
                  <Tippy key={marker.label} content={marker.label} arrow="">
                    <div
                      className={styles.marker}
                      style={{
                        left: `${marker.xCoord}%`,
                        top: `${marker.yCoord}%`,
                      }}
                    />
                  </Tippy>
                );
              })}
          </div>
        </div>
      </Element>
    </section>
  );
};

export default Map;
