import React from 'react';
import { Link } from 'gatsby';
import { Element } from 'react-scroll';
import Arrow from 'assets/icons/contact-arrow.svg';
import * as styles from 'styles/contact.module.scss';

const Contact = ({ cmsData }) => {
  const { contactContent } = cmsData.datoCmsHomepage;

  return (
    <section className={styles.sectionContact}>
      <Element name="contact" className="page-container">
        <h2 className={styles.h2}>{contactContent}</h2>
        <Link to="/contact" className={styles.arrowContainer}>
          <Arrow />
          <span className={styles.contactLabel}>
            Contact Us<span className={styles.blue}>.</span>
          </span>
        </Link>
      </Element>
    </section>
  );
};

export default Contact;
