import React from 'react';
import { Element } from 'react-scroll';
import CurvedCarousel from 'components/CurvedCarousel';
import LeftRightScroll from 'assets/icons/left-right-scroll.svg';

import * as styles from 'styles/clients.module.scss';

const Clients = ({ cmsData }) => {
  const { clientsTitle, clientsContent, clientsList } = cmsData.datoCmsHomepage;

  return (
    <section className={styles.sectionClients}>
      <Element name="clients" className="page-container">
        <h2 className={styles.h2}>{clientsTitle}</h2>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: clientsContent }} />
      </Element>

      <div className={styles.logos}>
        <div className={styles.icon}>
          <LeftRightScroll />
        </div>
        <CurvedCarousel childWidth={200} curve={-14} className={styles.curvedCarousel}>
          {clientsList.map(({ clientAgency, clientName, clientShort }) => (
            <div key={clientName} className={styles.logo}>
              <div className={styles.logoItem}>{clientShort || clientName}</div>
              <div className={styles.info}>
                <table>
                  <tbody>
                    <tr>
                      <td>name:</td>
                      <td>{clientName}</td>
                    </tr>
                    {clientAgency && (
                      <tr>
                        <td>scope:</td>
                        <td>{clientAgency}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </CurvedCarousel>
      </div>
    </section>
  );
};

export default Clients;
