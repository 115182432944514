import React, { useEffect, useState } from 'react';
import reactStringReplace from 'react-string-replace';
import TextLoop from 'react-text-loop';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Element } from 'react-scroll';
import * as styles from 'styles/hero.module.scss';

const Hero = ({ cmsData }) => {
  const { topHeading, mottoTexts } = cmsData.datoCmsHomepage;

  const [textInterval, setTextInterval] = useState(0);

  useEffect(() => {
    if (textInterval === 1) setTextInterval(0);
  }, [textInterval]);

  const toggleInterval = () => {
    setTextInterval(1);
  };

  return (
    <section className={styles.sectionHero}>
      <Element name="who-we-are" className="page-container">
        <h1 className={styles.motto}>
          {reactStringReplace(topHeading, '%word%', (match, i) => (
            <React.Fragment key={i}>
              <br />
              <div className={styles.wordsWrapper}>
                <TextLoop mask interval={textInterval} fade={false}>
                  {mottoTexts.map(({ text }) => (
                    <span key={text}>{text}</span>
                  ))}
                </TextLoop>{' '}
              </div>
            </React.Fragment>
          ))}
        </h1>
        <div className={styles.swiper}>
          <Swiper
            initialSlide={2}
            direction="vertical"
            speed={150}
            autoplay={{ delay: 3000 }}
            spaceBetween={10}
            slidesPerView={mottoTexts.length}
            style={{ height: `${mottoTexts.length * 30}px` }}
            loop
            onSlideChangeTransitionStart={toggleInterval}
          >
            {mottoTexts.map(({ text }) => (
              <SwiperSlide key={text}>
                <span className={styles.slideText}>{text}</span>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Element>
    </section>
  );
};

export default Hero;
