// extracted by mini-css-extract-plugin
export var accordion = "services-module--accordion--3IeFU";
export var accordionItemWrapper = "services-module--accordionItemWrapper--nfZxy";
export var active = "services-module--active--WfZ1l";
export var backArrow = "services-module--backArrow--7jvyY";
export var background = "services-module--background--G39TE";
export var backgroundItem = "services-module--backgroundItem--FC+1j";
export var backgroundWrapper = "services-module--backgroundWrapper--1GEq1";
export var pageContainer = "services-module--pageContainer--rwDwq";
export var sectionServices = "services-module--sectionServices--TdclJ";
export var serviceWrapper = "services-module--serviceWrapper--nY0cv";
export var servicesContent = "services-module--servicesContent--Ec28l";
export var servicesPicker = "services-module--servicesPicker--UCZMt";
export var technologiesWrapper = "services-module--technologiesWrapper--NGkkZ";
export var textMask = "services-module--textMask--bK4Ct";