import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';
import { Accordion } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring';
import gsap from 'gsap';
import * as styles from 'styles/services.module.scss';
import Arrow from 'assets/icons/contact-arrow.svg';
import ServiceContent from 'components/ServiceContent';
import { useWindowSize } from 'utils/useWindowSize';

const Services = ({ cmsData }) => {
  const { services } = cmsData.datoCmsHomepage;
  const { width: windowWidth } = useWindowSize();

  const isMobile =
    typeof window !== 'undefined'
      ? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
          window.navigator.userAgent
        )
      : false;
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const transition = (x, y) =>
    `translate3d(0,${windowWidth && !isMobile && windowWidth >= 992 ? -y / 8 : 0}px,0)`;
  const anim_tim = 0.75;

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 30, tension: 1550, friction: 300 },
  }));

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = (e.type === 'touchmove' ? e.touches[0].clientX : e.clientX) - rect.left;
    const y = (e.type === 'touchmove' ? e.touches[0].clientY : e.clientY) - rect.top;

    set({ xy: calc(x, y) });
  };

  const [isAnimating, setAnimating] = useState(false);
  const [selectedAccordion, setSelectedAccordion] = useState(null);
  const [hoveredAccordion, setHoveredAccordion] = useState(null);
  const backgroundsRefs = useRef(new Array(services.length));
  const liRefs = useRef(new Array(services.length));

  const handleSelectAccordion = (eventKey) => {
    const index = eventKey ? services.findIndex((service) => service.slug === eventKey) : null;
    const previousAccordion = selectedAccordion;
    setSelectedAccordion(index);
    if (!isAnimating) {
      setAnimating(true);
      const cloneLIs = [...liRefs.current];
      if (previousAccordion === null) {
        cloneLIs.splice(index, 1);
      } else {
        cloneLIs.splice(previousAccordion, 1);
      }
      const tl = gsap.timeline({
        onComplete: () => setAnimating(false),
        defaults: { duration: anim_tim },
      });
      if (index === null) {
        tl.to(cloneLIs, { height: `auto`, autoAlpha: 1 });
      } else {
        tl.to(cloneLIs, { height: 0, autoAlpha: 0 });
      }
    }
  };

  const handleHoverAccordion = (e, index) => {
    // const previousHover = usePrevious(hoveredAccordion)
    if (selectedAccordion === null) {
      if (e.type === 'mouseenter') {
        setHoveredAccordion(index);
        const tl = gsap.timeline({ defaults: { duration: anim_tim } });
        tl.fromTo(
          backgroundsRefs.current[index],
          { autoAlpha: 1, width: `0%` },
          { autoAlpha: 1, width: `100%` }
        );
      }

      if (e.type === 'mouseleave') {
        setHoveredAccordion(null);
        const tl = gsap.timeline({ defaults: { duration: anim_tim } });
        tl.to(backgroundsRefs.current[index], { autoAlpha: 0, duration: anim_tim });
        tl.set(backgroundsRefs.current[index], { autoAlpha: 1, width: `0%` });
      }
    }
  };

  return (
    <section className={styles.sectionServices}>
      <Element
        name="services"
        className={`page-container ${styles.pageContainer}`}
        onMouseMove={handleMouseMove}
        onTouchMove={handleMouseMove}
      >
        <Accordion
          className={styles.accordion}
          onSelect={(e) => handleSelectAccordion(e)}
          style={{
            pointerEvents: isAnimating ? `none` : ``,
          }}
        >
          <animated.ul
            className={styles.servicesPicker}
            style={{ transform: props.xy.to(transition) }}
          >
            {services &&
              services.map((service, index) => {
                return (
                  <li
                    key={`${service.slug}`}
                    onMouseEnter={(e) => handleHoverAccordion(e, index)}
                    onMouseLeave={(e) => handleHoverAccordion(e, index)}
                    ref={(el) => (liRefs.current[index] = el)}
                    className={classNames({
                      [`${styles.active}`]: selectedAccordion === index,
                    })}
                  >
                    <div className={styles.accordionItemWrapper}>
                      <Accordion.Toggle as="div" eventKey={service.slug}>
                        <Arrow
                          className={classNames({
                            [`${styles.backArrow}`]: true,
                            [`${styles.active}`]: selectedAccordion === index,
                            link: true,
                          })}
                        />
                        <div
                          className={classNames({
                            link: true,
                            [`${styles.textMask}`]: true,
                          })}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: service.title.replace('\n', '<br/>'),
                            }}
                          />
                        </div>
                        <span
                          dangerouslySetInnerHTML={{ __html: service.title.replace('\n', '<br/>') }}
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={service.slug}>
                        <div>
                          <div>
                            <div className={styles.servicesContent}>
                              <ServiceContent
                                content={service.content}
                                className={styles.serviceWrapper}
                              />

                              <div className={styles.technologiesWrapper}>
                                <strong
                                    dangerouslySetInnerHTML={{ __html: service.technologiesTitle }}
                                />
                                  <ul>
                                  {service.technologies &&
                                    service.technologies.map((technology) => (
                                      <li key={technology.technologyName}>
                                        {technology.technologyName}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </li>
                );
              })}
          </animated.ul>
        </Accordion>
        <div className={styles.backgroundWrapper}>
          {services &&
            services.map((service, index) => (
              <div
                className={styles.backgroundItem}
                key={service.title}
                ref={(el) => (backgroundsRefs.current[index] = el)}
                style={{
                  zIndex:
                    selectedAccordion === index ||
                    (selectedAccordion === null && hoveredAccordion === index)
                      ? `2`
                      : `1`,
                }}
              >
                <div
                  className={styles.background}
                  style={{ backgroundImage: `url("${service.backgroundImage.url}")` }}
                />
              </div>
            ))}
        </div>
      </Element>
    </section>
  );
};

export default Services;
