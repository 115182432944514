import React from 'react';
import { Element } from 'react-scroll';
import * as styles from 'styles/content.module.scss';

const Content = ({ cmsData }) => {
  const { whatWeDoTitle, whatWeDoContent } = cmsData.datoCmsHomepage;

  return (
    <section className={styles.sectionContent}>
      <Element name="what-we-do" className="page-container">
        <h2>{whatWeDoTitle}</h2>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: whatWeDoContent }} />
      </Element>
    </section>
  );
};

export default Content;
