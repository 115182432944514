import React from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';
import CurvedCarousel from 'components/CurvedCarousel';
import { useWindowSize } from 'utils/useWindowSize';
import * as styles from 'styles/process.module.scss';

const Process = ({ cmsData }) => {
  const { processTitle, process } = cmsData.datoCmsHomepage;
  const { width: windowWidth } = useWindowSize();

  return (
    <section className={styles.sectionProcess}>
      <Element name="process">
        <h2 className={classNames(styles.h2, styles.bigger, 'bigger')}>{processTitle}</h2>
        <div className={styles.cards}>
          {windowWidth && (
            <CurvedCarousel
              childWidth={windowWidth && windowWidth * 0.9 < 650 ? windowWidth * 0.9 : 650}
              spacing={windowWidth ? (windowWidth >= 992 ? 150 : 60) : 150}
              curve={10}
              fixTop
              className={styles.curvedCarousel}
            >
              {process.map(({ title, content, slug, backgroundImage }, index) => (
                <div className={styles.card} key={slug}>
                  <div
                    className={styles.background}
                    style={{ backgroundImage: `url("${backgroundImage?.url}")` }}
                  />
                  {backgroundImage && <div className={styles.label}>Step #{index + 1}</div>}
                  <h3>{title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              ))}
            </CurvedCarousel>
          )}
        </div>
      </Element>
    </section>
  );
};

export default Process;
